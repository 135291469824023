@import "../../../../assets/styles/_colors";
@import "../../../../assets/scss/mdb-free.scss";

.dclogo {
  height: 2rem;
  margin-left: 1rem;
}

.pos-rel {
  position: relative;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pr-10 {
  padding-right: 15px;
}

.h-auto {
  height: auto;
}

.h-70 {
  height: 70px;
}

.badge {
  border-radius: 50%;
  position: absolute;
  top: -0.8rem;
  right: -0.5rem;
  padding: 2px 5px;
  border-radius: 50%;
  background-color: #ff9100;
  color: #fff;
}

.navbar-nav {
  flex-direction: row;

  .css-2b097c-container {
    width: 13rem !important;
    margin-right: 1rem;
  }
}

//modal to choose branch
.branches-modal {
  .modal.show .modal-dialog {
    margin-top: 5%;
  }

  .modal-dialog .modal-content {
    border-radius: 1.8rem;
    padding: 2.8rem;
  }

  .modal-header {
    border-bottom: none;
    // padding: 2rem;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: left;
    color: #2d2ace;
  }

  .modal-subtitle {
    padding-left: 1rem;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #252e48;
  }

  .branch-container {
    margin: 10px;
    width: 130px;
    height: 150px;
    border-radius: 10px;
    background-color: #f5f5fa;
    padding: 0.5rem;
    padding-top: 1.2rem;
    cursor: pointer;
  }

  .branch-image {
    margin: auto;
    text-align: center;
    padding-bottom: 0.8rem;
    padding-top: 1rem;
  }

  .branch-title {
    margin: auto;
    width: 100px;
    color: #2d2ace;
    font-size: 15px;
    text-align: center;
    // font-weight: bold;
    overflow-wrap: break-word;
  }
}

.notification-dot {
  position: absolute;
  padding: 4px;
  background-color: #2d2ace;
  border-radius: 50%;
}

.notification-popover {
  background-color: white;
  position: absolute;
  z-index: 50;
  top: 3.6rem;
  transform: translateX(-33.5rem);
  box-shadow: 0px 3px 5px 1px #bbb6b6;
  min-width: 35rem;
  max-width: 35rem;
  text-align: center;

  .card-notification-tray {
    background-color: rgba(241, 241, 241, 0.7);
    border-radius: 7px;
  }
}

// @media screen and (max-width: 780px) {
//   .badge {
//     border-radius: 50%;
//     position: absolute;
//     top: 1rem;
//     right: 1rem;
//     padding: 1px 4px;
//     border-radius: 50%;
//     background-color: #ff9100;
//     color: #fff;
//   }
// }

.mobile-header-bars {
  display: none;
}

.header-banner {
  overflow: hidden;
  max-height: 10rem;
  transform: translateY(-1.4rem);
  border-radius: 1rem;

  img {
    width: 104%;
    transform: translateY(-2.5rem);
    object-fit: contain;
  }
}

@include media-breakpoint-down(xs) {}

@include media-breakpoint-down(sm) {
  .mobile-header-bars {
    display: inherit;
  }

  .header-banner {
    img {
      transform: translateY(0.5rem);
    }
  }
}

@include media-breakpoint-down(md) {}

@include media-breakpoint-down(lg) {}

@include media-breakpoint-up(sm) {}

@include media-breakpoint-up(md) {}

@include media-breakpoint-up(lg) {}

@include media-breakpoint-up(xl) {}