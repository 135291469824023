@keyframes ldio-riyhi3durel {
  0% {
    transform: translate(12px, 80px) scale(0);
  }
  25% {
    transform: translate(12px, 80px) scale(0);
  }
  50% {
    transform: translate(12px, 80px) scale(1);
  }
  75% {
    transform: translate(80px, 80px) scale(1);
  }
  100% {
    transform: translate(148px, 80px) scale(1);
  }
}

@keyframes ldio-riyhi3durel-r {
  0% {
    transform: translate(148px, 80px) scale(1);
  }
  100% {
    transform: translate(148px, 80px) scale(0);
  }
}

@keyframes ldio-riyhi3durel-c {
  0% {
    background: #1d3f72
  }
  25% {
    background: #71c2cc
  }
  50% {
    background: #d8ebf9
  }
  75% {
    background: #5699d2
  }
  100% {
    background: #1d3f72
  }
}

.ldio-riyhi3durel div {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transform: translate(80px, 80px) scale(1);
  background: #1d3f72;
  animation: ldio-riyhi3durel 1s infinite cubic-bezier(0, 0.5, 0.5, 1);
}

.ldio-riyhi3durel div:nth-child(1) {
  background: #5699d2;
  transform: translate(148px, 80px) scale(1);
  animation: ldio-riyhi3durel-r 0.25s infinite cubic-bezier(0, 0.5, 0.5, 1), ldio-riyhi3durel-c 1s infinite step-start;
}

.ldio-riyhi3durel div:nth-child(2) {
  animation-delay: -0.25s;
  background: #1d3f72;
}

.ldio-riyhi3durel div:nth-child(3) {
  animation-delay: -0.5s;
  background: #5699d2;
}

.ldio-riyhi3durel div:nth-child(4) {
  animation-delay: -0.75s;
  background: #d8ebf9;
}

.ldio-riyhi3durel div:nth-child(5) {
  animation-delay: -1s;
  background: #71c2cc;
}

.loadingio-spinner-ellipsis-0yial3r2g3t {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}

.ldio-riyhi3durel {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.ldio-riyhi3durel div {
  box-sizing: content-box;
}

/* generated by https://loading.io/ */