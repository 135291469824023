.select{
    border-radius: 10px;
    color:#828282;
    border:1px solid #fff;
    outline: none;
    box-sizing: content-box;
    padding: 0.8rem 1.2rem;
    width: 38%;
    outline: none;
    box-sizing: content-box;
    background-color: #f2f2f2;
    border:1px solid #f2f2f2;
}
#view-select{
    width: auto;
    background-color: #fff;
    border:1px solid #fff;
}