.request-container input[type=text] {
    border: solid 1px #e0e7ff;
    background-color: #fff;
    color: #2e384d;
    border-radius: 10px;
    padding: 1rem 1.5rem;
    width: 100%;
    &:focus{
        outline: none;
    }
}
