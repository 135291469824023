$offWhite: #f5f5fa;
$custom-blue:#2d2ace;
$lightGrey: #9698ab;
$fontBlue: #2D2ACE;

.bg-offwhite{
    background-color: $offWhite;
}

.bg-custom-blue{
    background-color: $custom-blue;
}

.text-font-blue{
    color: $fontBlue
}
