.multiselect-dropdown{
    width: 102% !important;
}
.css-yk16xz-control{
    border-radius: 10px !important;
    background-color: #f2f2f2 !important;
    border-color: #f2f2f2 !important;
    padding: 0.2rem;
    margin-left: 0;
    outline: none;
} 
.css-yk16xz-control:focus{
    border-radius: 10px !important;
    box-shadow: none !important;
    background-color: #f2f2f2 !important;
    border-color: #f2f2f2 !important;
}
.css-yk16xz-control:hover{
    border-radius: 10px !important;
    box-shadow: none !important;
    background-color: #f2f2f2 !important;
    border-color: #f2f2f2 !important;
}
.css-1pahdxg-control{
    border-radius: 10px !important; 
    box-shadow: none !important;
    background-color: #f2f2f2 !important;
    border-color: #f2f2f2 !important;
}
.css-1pahdxg-control:hover{
    border-radius: 10px !important; 
    box-shadow: none !important;
    background-color: #f2f2f2 !important;
    border-color: #f2f2f2 !important;
}