@import "../../../../assets/scss/mdb-free.scss";
@import '../../../../assets/styles/_colors';

.nested-link {
  a {
    li {
      padding: 1.5rem 0 1rem 1rem !important;
      .link-text {
        h4 {
          font-size: 0.9rem;
        }
      }
    }
  }
}

.link-text {
  transition: all 0.4s linear;
  overflow: hidden;
}

.closed {
  .link-text {
    display: none !important;
  }
  li {
    align-items: center;
    padding: 0.75rem 0rem;
    z-index: 1000;
    i {
      font-size: 1.5em;
    }
  }

  .collaps-arrow {
    display: none;
  }

  .nested-link {
    a {
      display: none;
    }
  }
}

.opened{
  li {
    padding: 1.75rem 0rem 0 1rem !important;
  }

  .collaps-arrow{
    position: absolute;
    right: 15px;
  }
}  

.my-account-side-link{
  li{
  margin-bottom: 3rem;
  }
}

.settings-side-link{
  li{
  margin-top: 3rem;
  }
}

.side-header-account{
  display: none;
  &-icon{
    padding-left: 0.3rem !important;
  }
}

.status-dot{
  content: "";
  position: absolute;
  background-color: #03eb22;
  padding: 5px;
  border-radius: 50%;
}

.online{
  &::after{
    @extend .status-dot;
    bottom: 0.1rem;
  }
}

.online-link{
  i, .my-account-img{
  &::after{
    @extend .status-dot;
    bottom: 0.1rem;
    left: 0.8rem;
   }
  }
}

.side-header{
  background-color: $custom-blue;
  hr{
    height: 1.5px;
    background-color: $white;
    margin-top: 0;
    margin-bottom: 0;
  }
  hr:nth-child(1){
    width: 25px;
  }
  hr:nth-child(2){
    width: 15px;
    margin-top: 0.3rem;
    transform: translateX(-5px);
  }
}

.mobile-side-close{
  display: none;
}

.side-links{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 85vh;
  
  &::-webkit-scrollbar {
    width: 0.4em;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &:hover{
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
    }
  }
}

.last-side-link{
  li{
    padding-bottom: 2rem !important;
  }
}

.link-icon-dimensions{
    width: 1.3rem;
    height: 1.3rem;
}

.active-link{
  img{
    padding: 3px;
    // background-color: #d4cef8;
    box-shadow: 0 0 0 5px #d4cef8, 0 0 0 10px #e8e5fc;
    border-radius: 40%;
    position: relative;
    z-index: 2;
  }
}

@include media-breakpoint-down(xs) {
}

@include media-breakpoint-down(sm) {
  .side-header-account{
    display: inherit;
    transform: translate(0, 1.5rem);
  }

  .my-account-side-link{
    display: none;
  }

  .side-header{
    background-color: white;
    min-height: 15vh;
    .bars{
      display: none;
    }
  }

  .mobile-side-close{
    position: absolute;
    right: -3rem;
    display: inherit;
    background-color: rgba(0, 0, 0, 0.55);
    width: 3rem;
    border-top-right-radius: 0.7rem;
    border-bottom-right-radius: 0.7rem;
  }

  .side-links{
    min-height: 85vh;
  }
}

@include media-breakpoint-down(md) {
}

@include media-breakpoint-down(lg) {
}

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-up(md) {
}

@include media-breakpoint-up(lg) {
}

@include media-breakpoint-up(xl) {
}
