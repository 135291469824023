/* custom checkbox */
        /* Customize the label (the container) */
        
        .checkbox-container {
            display: block;
            position: relative;
            padding: 5px;
            padding-left: 30px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 12px;
            color: #1e1e1e;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        /* Hide the browser's default checkbox */
        
        .checkbox-container input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        /* Create a custom checkbox */
        
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            border-radius: 6px;
            background-color: #fff;
        }
        .row-checkbox {
            background-color: #f1f1f1;
        }
        /* On mouse-over, add a grey background color */
        
        .checkbox-container:hover input[type="checkbox"]~.checkmark {
            background-color: #ccc;
        }
        /* When the checkbox is checked, add a blue background */
        
        .checkbox-container input[type="checkbox"]:checked~.checkmark {
            background-color: #2196F3;
        }
        /* Create the checkmark/indicator (hidden when not checked) */
        
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        /* Show the checkmark when checked */
        
        .checkbox-container input:checked~.checkmark:after {
            display: block;
        }
        /* Style the checkmark/indicator */
        
        .checkbox-container .checkmark:after {
            left: 8px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }