.table-dropdown {
    text-align: center;

    .btn {
        box-shadow: none;
        width: 2px;
        padding: 5px;
        margin: 1px;
    }

    .btn:hover {
        box-shadow: none;
    }

    .btn:focus {
        box-shadow: none;
    }

    .dropdown-menu {
        background-color: #f9f9f9;
        width: 14rem;
        color: #252e48;
        border-radius: 0.5rem;
        border-top-right-radius: 0;
        border: 0px;
        box-shadow: 1px 0px 5px 0px #eeee;
    }

    .dropdown-item,
    .dropdown-item:not([disabled]):not(.disabled):active {
        color: #252e48;
        word-wrap: break-word;
        white-space: pre-wrap;
        font-size: 90%;
        // padding: 0.8rem; 
        // margin-left: 1rem;
        outline: none;

        &:hover {
            background-color: transparent;
        }

        &:focus {
            background-color: transparent !important;
            border: none !important;
            outline: none;
            box-shadow: none;
        }
    }
}

.menu {
    background-color: #f9f9f9;
    width: 12rem;
}

.menuIcon {
    color: #9698ab;
}

.menuItem {
    color: #252e48;

}