@import "_font_styles";
@import "_colors";
@import "../scss/mdb-free.scss";

html,
body,
#root {
  height: 100%;
  background-color: $offWhite;
}

.cursor {
  cursor: pointer;
}

.cursor-disabled {
  cursor: no-drop;
}

.page,
body {
  background-color: $offWhite;
}

.box-shadow-none{
  box-shadow: none !important;
}

.box-shadow {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.position-relative {
  position: relative;
}

.transition-04 {
  transition: 0.4s linear;
}

.transition-07 {
  transition: 0.7s linear;
}

.btn-rounded {
  border-radius: 15px;
}

.input-rounded {
  border-radius: 10px;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  &:focus {
    box-shadow: none;
    background-color: #f2f2f2;
    border-color: #f2f2f2;
  }
}
.input-rounded::placeholder {
  color: #d5d5d5;
}
.page-title{
  height:6vh;
  font-size: 20px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: normal;
  text-align: left;
  color: #252e48;
  margin:0rem 0.8rem;
}
.page-image{
height: 4vh;
}
.page-line{
  border-bottom: 3px solid #2d2ace;
  width: 3rem;
  height: 1.5vh;
}
.z-index-1 {
  z-index: 1;
}

.pt-6 {
  padding-top: 4rem !important;
}

.mb-8 {
  margin-bottom: 6rem !important;
}

.pr-8-rem {
  padding-right: 8rem !important;
}

.pr-13-rem {
  padding-right: 13rem !important;
}

.table td {
  vertical-align: middle !important;
}

.close {
  &:focus {
    outline: none !important;
  }
  span {
    &:focus {
      outline: none !important;
    }
  }
}
label{
  color:#8c98a9;
}
.fit-width {
  width: fit-content;
}

.search-input {
  padding: 20px 18px 20px 18px !important;
  outline: none !important;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  &:focus {
    box-shadow: none;
    background-color: #f2f2f2;
    border-color: #f2f2f2;
  }
}
.resize-none {
  resize: none;

}
.text-area{
  border-radius: 10px;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  outline: none !important;
  &:focus {
    box-shadow: none;
    outline: none !important;
    background-color: #f2f2f2;
    border-color: #f2f2f2;
  }
}
.text-area::placeholder {
  color: #d5d5d5;
}

.border-radius {
  $radius: 1rem;
  &-tl {
    border-top-left-radius: $radius;
  }
  &-tr {
    border-top-right-radius: $radius;
  }
  &-bl {
    border-bottom-left-radius: $radius;
  }
  &-br {
    border-bottom-right-radius: $radius;
  }
}

.collaps-arrow {
  transition: 0.5s;
  &.up {
    transform: rotate(180deg);
  }
}

.styled-input{
  border: none;
  overflow: hidden;
  &, &:focus{
  background-color: #f5f5f5;
  outline: none;
  box-shadow: none;
  }
}

.full-height-modal{
  width: 60vw !important;
  max-width: 60vw !important;
  position: absolute;
  top: 0;
  display: flex;
  height: auto;
  min-height: 100%;
  margin: 0;
  .modal-content{
    border-bottom-left-radius: 2rem !important;
    border-top-left-radius: 2rem !important;
  }
}
.full-height-request-modal{
  width: 80vw !important;
  max-width: 80vw !important;
}

.full-height-modal-right{
  right: 0;
}

.custom-modal-header{
  border-bottom: none !important;
  background-color: #f5f5f5;
  border-top-left-radius: 2rem !important;
}

.d-contents{
  display: contents !important;
}

.button-container .btn-modal-header-save{
  // padding: 0.8rem 1.8rem !important;
  width: 7.5rem;
  margin-left: 1.5rem;
}

.button-container .btn-modal-header-cancel{
  // padding: 0.9rem 1.8rem !important;
  margin-right: 1rem !important;
  width: 7.5rem;
  &, &:hover{
      color: $lightGrey;
  }
}

.actions-container{
  .button-container .btn-modal-header-cancel, .button-container .btn-modal-header-cancel:not([disabled]):not(.disabled):active{
      background-color: white !important;
  }
}

.horizontal-scroll{
    overflow-x: auto;
}

.vertical-scroll{
  overflow-y: auto;
}

.transaction-table-title{
    padding-left: 4rem;
}

.transaction-table{
  .uncelled-table{
    padding-top: 1rem;
  }
}
.view-all-link{
  &, &:hover, &:visited, &:focus{
  color: #252e48;
  text-decoration: underline;
  }
}
//date picker
.react-datepicker{
  border:1px solid #2D2ACE !important;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before{
  visibility: hidden;
}
.react-datepicker__navigation--previous {
  border-right-color: #fff !important;
  outline: none !important;
}
.react-datepicker__navigation--next {
  border-left-color: #fff !important;
  outline: none !important;
}
.react-datepicker__header {
  background-color: #2D2ACE !important;
  border-bottom: none !important;
  border-radius: 0px !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #fff !important;
}
.react-datepicker__day-name {
  color: #fff !important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected,.react-datepicker__day--selected{
  background-color: #2D2ACE !important;
}
.word-break{
  word-break: break-all;
}

.white-background {
  background-color: #fff;
  padding: 2rem;
  border-radius: 15px;
}
@include media-breakpoint-down(xs) {
}

@include media-breakpoint-down(sm) {
  .sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    max-width: 65.666667%;
    .border-radius {
      &-tl {
        border-top-left-radius: 0;
      }
      &-tr {
        border-top-right-radius: 0;
      }
      &-bl {
        border-bottom-left-radius: 0;
      }
      &-br {
        border-bottom-right-radius: 0;
      }
    }
  }

  .sidebar-closed {
    left: -500px;
  }

  .full-height-modal{
    width: 80vw !important;
    max-width: 80vw !important;
  }

}

@include media-breakpoint-down(md) {
}

@include media-breakpoint-down(lg) {
}

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-up(md) {
}

@include media-breakpoint-up(lg) {
}

@include media-breakpoint-up(xl) {
}
