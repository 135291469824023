@import '../scss/mdb-free.scss';
@import './_colors';

.text-weight-normal{
  font-weight: 600 !important;
}

.text{
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
}

.medium-text {
  @extend .text;
  font-size: medium;
}

.smaller-text {
  @extend .text;
  font-size: smaller;
}

.extra-small-text {
  @extend .text;
  font-size: x-small;
}

.word-break{
  word-break: break-word;
}

.offblue-text{
  color: $custom-blue;
  font-weight: bold;
  &:hover{
    color: $custom-blue !important;
  }
}

.lightGrey-text{
  color: $lightGrey;
}

.fa-1_5x {
  font-size: 1.5em;
}

.text-upperCase{
  text-transform: uppercase;
}

.text-weight-400{
  font-weight: 400;
}

@include media-breakpoint-down(xs) { 

}

@include media-breakpoint-down(sm) { 

}

@include media-breakpoint-down(md) { 

}

@include media-breakpoint-down(lg) { 

}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}
