@import '../../assets/styles/_colors';

.displayFlexColumn {
    display: flex;
    flex-direction: column;
}

.featureStyle {
    color: #2D2ACE;
    padding: 0px;
}

.product-img {
    height: 25vh;
    width: inherit;
    object-fit: cover;
}

.select {
    width: 100%;
    color: gray;
    font-weight: 400;

    .search-result {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .price {
        color: #2D2ACE;
        font-weight: 500;
    }

}

.product-item {
    .product-img {
        width: 50px;
        height: 50px;
    }

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

}

.button {
    .btn {
        width: 90%;
    }
}

.products-container {
    margin-top: 10px;
}

.add-to-cart {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #2D2ACE !important;
    padding: 15px 10px;
    width: 80%;
    border-radius: 3px;
    box-shadow: none;

    p {
        margin: 0;
    }
}

.quotation {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #E0E7FF !important;
    padding: 15px 10px;
    width: 80%;
    border-radius: 3px;
    box-shadow: none;

    p {
        color: #2D2ACE;
        margin: 0;
    }
}

.in-cart {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #F5F5FA !important;
    padding: 15px 10px;
    width: 80%;
    border-radius: 3px;
    box-shadow: none;

    p {
        color: #494949;
        margin: 0;
    }
}

.product-image {
    width: 50px;
    height: 50px;
    overflow: hidden;
}