.signin-container{
    background-image: url("./../../assets/img/pic1.jpg");
    background-size:cover;
    background-repeat: no-repeat;
    position:absolute;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
}

.loginbox{
    width: 320px;
    height: 420px;
    background: #fff;
    color: #05487c;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%,-50%);
    box-sizing: border-box;
    padding: 70px 30px;
    box-shadow:5px 5px 5px #c6c6c6 ;
}

.avatar{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: -50px;
    left: calc(50% - 50px);
}

.loginbox h1{
    margin: 0;
    padding: 0 0 20px;
    text-align: center;
    font-size: 22px;
    color: #05487c;
    font-weight: bold;
}

.loginbox p{
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 18px;
    line-height: inherit;
}

.loginbox input{
    width: 100%;
    margin-bottom: 20px;
}

.loginbox input[type="text"], input[type="password"]
{
    border: none;
    border-bottom: 1px solid #000;
    background: transparent;
    outline: none;
    height: 40px;
    color: #000;
    font-size: 16px;
}
.loginbutton
{
    border: none;
    outline: none;
    height: 40px;
    background: #ff9100;
    color: #fff;
    font-size: 18px;
    border-radius: 10px;
}
.loginbox input[type="submit"]:hover
{
    cursor: pointer;
    background: #ff7800;
    color: #fff;
}
.loginbox a{
    text-decoration: none;
    font-size: 12px;
    line-height: 20px;
    color: darkgrey;
}

.loginbox a:hover
{
    color: #ff9100;
}
.loginbox > * {
    opacity: .9;
  }
  
  .loginbox > .loginbutton {
    opacity: 1;
  }