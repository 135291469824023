.d-flex-col {
    display: flex;
    flex-direction: column;
}

.not-available {
    color: red !important;
    font-size: 10px;
    font-weight: bold;
}

.max-height-dentacarts {
    max-height: 20rem;
}


.selected-payment {
    .card-body {
        border: 1px solid #2d2ace;
    }
}

.cart-summary-container {
    display: flex;
    justify-content: flex-end;
    // margin-top: 20px;
    z-index: 100000000;
}

.card-container {
    border: none;
    position: fixed;
    bottom: 20px;
    right: 25px;
    // width: 25%;
}

.card-header {
    background-color: transparent;
    width: 70%;
}


.summary-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 200px;

}

.summary-item-title {
    font-size: 18px;
    font-weight: bold;
}

.summary-item-value {
    font-size: 14px;
    font-weight: bold;
}

.summary-total-value {
    font-size: 18px;
}


.payment-button {
    background-color: #2d2ace !important;
    box-shadow: none !important;
    z-index: 10000;
}

.delete-icon {
    width: 25px;
    height: 25px;

    &:hover {
        box-shadow: 2px 2px 5px 0 #eee !important;
    }
}


.confirmation-modal-container {
    .modal-header h4 {
        font-size: 16px !important;
        font-weight: bold !important;
    }

    .product-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex: 1;
    }

    .product-name {
        font-size: 12px;
        font-weight: 500;
    }

    .modal.show .modal-dialog {
        margin-top: 10%;
    }

    .modal-dialog .modal-content {
        border-radius: 1.5rem;
        font-size: 1.5vw;
    }

    .modal-content {
        padding: 1.7rem;
        font-weight: bold;
    }

    .modal-header {
        border-bottom: none;
    }

    .modal-footer {
        justify-content: center;
        border-top: none;
        padding: 1.7rem;
    }

    .confirm-btn,
    .confirm-btn:not([disabled]):not(.disabled):active {
        padding: 0.9rem 2rem;
        border-radius: 10px !important;
        background-color: #2d2ace !important;
        box-shadow: none !important;
        margin: 0px;
        text-transform: capitalize;
    }

    .cancel-btn,
    .cancel-btn:not([disabled]):not(.disabled):active {
        padding: 0.9rem 2rem;
        border-radius: 10px !important;
        box-shadow: none !important;
        margin: 0px;
        background-color: #e9e9e9 !important;
        color: #474747 !important;
        text-transform: capitalize;
    }
}

.product-image {
    width: 50px;
    height: 50px;
    overflow: hidden;
}