.branches-buttons {
    border-radius: 20px;
    box-shadow: 2px 2px 5px 0 #d5d5d5 !important;
}

.deactivate {
    color: #acacac !important;
    border-color: transparent !important;
}

.deactivate:hover {
    color: #acacac !important;
    border-color: transparent !important;
}

.select-container {
    margin-left: 2px
}

.table-content {
    font-weight: bold;
}

.status-container {
    font-weight: 500;
    color: #fff;
    padding: 5px;
    border-radius: 15px;
    width: 40%;
    text-align: center;
}

.inprogress {
    background-color: #FFCA1D;
}

.shipping {
    background-color: #2D2ACE;
}

.delivered {
    background-color: #35CE2A;
}

.incomplete {
    background-color: #EC3030;
    ;
}