.tabs-container {
    width: fit-content;
}

.tabs-button {
    position: relative;
    width: 120px;
    // font-weight: bold;
    font-size: 20px !important;
    height: 40px;
    margin: 0 0 0 15px;
    padding: 0px 0px 0px 16px !important;
    border-radius: 10px !important;
    line-height: 1.19;
    letter-spacing: 0.42px;
    text-align: start;
    box-shadow: none !important;
}

.tabs-button:hover {
    box-shadow: none !important;
}

#inactive-button {
    color: #252e48 !important;
    background-color: #f5f5fa !important;
    // border: 1px solid #252e48;
}

#active-button {
    background-color: transparent !important;
    color: #252e48 !important;
    // border-bottom: solid 3px #2D2ACE !important;
    border-color: #e0e7ff;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 36px;
        left: 0px;
        width: 100%;
        border-radius: 100px;
        border: 6px solid #2D2ACE;
    }
}