.uncelled-table {
    background-color: #fff;
    padding: 2rem;
    border-radius: 15px;
    // width: 80%;
    .cursor:hover {
      background-color: transparent;
    }
    .ui.table thead{
      background-color: #f5f5f5;
      border-radius: 10px;
  }
  .ui.table thead th{
   border-bottom:none
  }
    .ui.table {
      border: none;
    }
    .ui.table tr td {
      border-top: none;
    }
    .ui.celled.table tr td,
    .ui.celled.table tr th {
      border-left: none;
    }

    .ui.table thead {
      font-size: 15px;
    }
    // pagination custom style
    .ui.table tfoot th{
      border:none;
      background: #f5f5fa;
    }
  
    .ui.pagination.menu{
      width: fit-content;
      flex-wrap: wrap;
      border: none;
      box-shadow: none;
      background: #f5f5fa;
    }
    .ui.pagination.menu .item{
      border-radius: 10px;
      margin: 0px 10px 0px 10px;
      background-color: #ffffff;
      color:#252e48;
      outline: none;
      border:none !important;
  
    }
    .ui.pagination.menu .active.item{
      border-radius: 10px;
      margin: 0px 10px 0px 10px;
      background-color: #2d2ace;
      color:#fff;
      outline: none;
      border:none !important;
  
    }
    .ui.pagination.menu .item:last-child {
      border-radius: 12px;
    }
    .ui.menu .item:before{
      background-color: transparent;
    }
  }
  @media (max-width: 780px) {
    .uncelled-table {
      .ui.table thead tr:first-child > th:last-child {
        text-align: left;
      }
    }
  }