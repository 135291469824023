.quotation-status {
  width: 40%;
  color: white;
  padding: 5px 10px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 400;

  p {
    text-align: center;
  }
}

.quotation-status-modal {
  width: 50%;
  color: white;
  padding: 15px 20px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  margin-left: 90px;

  p {
    text-align: center;
  }
}

.icon-container {
  background-color: #E6E6E6;
  padding: 20px;
  border-radius: 8px;
  border: none;

  img {
    width: 40px;
    height: 40px;
  }
}

.details-container {
  padding: 15px 35px;
}

.label {
  font-size: 14px;
  font-weight: 500;
  color: #8C98A9;
}

.date {
  background-color: #F5F5F5;
  width: 100%;
  padding: 15px 20px;
  color: #2E384D;
  font-size: 13px;
  font-weight: 400;
}

.details-fillter-button {
  outline: none;
  border: 1px solid #DCDCDC;
  padding: 10px 20px;
  color: #252E48;
  font-size: 15px;
  font-weight: 400;
  border-radius: 7px;

  &:focus {
    outline: none;
  }
}

.p-t-20 {
  padding-top: 20px;
}

.flex-row {
  display: flex;
  align-items: center;
}

.modal-title {
  margin: 0px 0px 0px 10px;
  padding: 0px;
  font-weight: bold;
}

.scroll {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 400px;

  .quotation-details-table {
    padding-right: 0px;
    padding-left: 0px;
    // margin-top: 20px;
  }
}